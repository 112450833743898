<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__nav">
        <a
          href="https://www.mars.com/privacy-policy-russia"
          target="_BLANK"
          class="footer__nav-link"
          @click="gtmEvent('Конфиденциальность')"
        >
          Конфиденциальность
        </a>
        <a
          href="https://www.mars.com/legal-russia"
          target="_blank"
          class="footer__nav-link"
          @click="gtmEvent('Юридические условия')"
        >
          Юридические условия
        </a>
        <a
          href="https://www.mars.com/"
          target="_blank"
          class="footer__nav-link"
          @click="gtmEvent('Владелец сайта')"
        >
          Владелец сайта
        </a>
        <a
          href=""
          @click.prevent="toFeedback(), gtmEvent('Задать вопрос о продукте')"
          class="footer__nav-link"
        >
          Задать вопрос о продукте
        </a>
        <a
          :href="`${publicPath}docs/user_agreement.pdf`"
          class="footer__nav-link"
          target="_blank"
          @click="gtmEvent('Пользовательское соглашение')"
        >
          Пользовательское соглашение
        </a>
      </div>
      <div class="footer__text">
        Организатор акции ООО &laquo;ЕДАДИЛ ПРОМО&raquo; (ИНН 7704337959). Общий
        срок проведения акции: с&nbsp;11.11.2024 по&nbsp;28.02.2025.
        <br />Срок регистрации и&nbsp;совершения покупок: с&nbsp;11.11.2024
        по&nbsp;05.01.2025. Количество призов и&nbsp;территория проведения акции
        ограничены. <br />Полная информация об&nbsp;организаторе акции, правилах
        и&nbsp;территории её&nbsp;проведения, акционной продукции, количестве
        призов, сроках, месте и&nbsp;порядке их&nbsp;получения размещена
        на&nbsp;<a
          href="http://www.snickers.ru"
          target="_blank"
          class="_undeline-link"
          >www.snickers.ru</a
        >
      </div>
      <p class="footer__copyright">
        {{ "© 2023 Mars, Incorporated. Все права защищены" | typograf }}
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    toFeedback() {
      this.$modal.show("feedback");
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  padding: rem(20px) 0 rem(22px);
  text-align: left;
  background: #2c0f07;
  color: #a9918b;
  z-index: 10;
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: rem(15px);
  }

  &__nav-link {
    position: relative;
    margin-bottom: rem(20px);
    font-size: rem(12px);
    text-align: center;
    line-height: rem(22px);
    color: #a9918b;
  }

  &__nav-link:last-child {
    margin-right: 0;
  }

  &__nav-link:hover {
    text-decoration: underline;
  }
  &__text {
    font-size: rem(10px);
    text-align: center;
    color: rgba(169, 145, 139, 0.6);
    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  &__copyright {
    margin: rem(30px) auto 0;
    font-size: rem(12px);
    text-align: center;
    color: #a9918b99;
  }
  @media (min-width: $md) {
    padding: rem(40px) rem(123px) rem(22px);
    &__row {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__nav {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__nav-link {
      margin-bottom: rem(30px);
      text-align: left;
      font-size: rem(19px);
    }
    &__copyright {
      margin-top: rem(90px);
      font-size: rem(18px);
    }
  }
  @media (min-width: $md) {
    padding: rem(40px) 0 rem(22px);
    &__nav {
      margin-bottom: rem(30px);
    }
    &__nav-link {
      text-decoration: none;
      margin-bottom: 0;
    }
    &__text {
      font-size: rem(12px);
    }
    &__col {
      flex: 0 0 35%;
      &:last-of-type {
        align-items: flex-start;
      }
    }
    &__copyright {
      max-width: 30%;
      margin: rem(20px) auto 0;
      font-size: rem(14px);
    }
  }
}
</style>
