<template>
  <div class="enter-action-wrapper hidden-xs">
    <div class="container">
      <div class="enter-action">
        <div class="enter-action__block _left-block">
          <div class="enter-action__text _yellow">Жми<br />сюда</div>
          <img
            src="../assets/images/icons/arrow_2.svg"
            alt=""
            class="enter-action__icon"
          />
        </div>
        <div class="enter-action__block _right-block">
          <div class="enter-action__text _yellow">
            Возврат <br />до 20% <br />здесь
          </div>
          <img
            src="../assets/images/icons/arrow_1.svg"
            alt=""
            class="enter-action__icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      const btn1 = document.querySelector(
        ".info-button__container"
      )?.offsetWidth;
      const btn2 = document.querySelector(".scan-button__wrapper")?.offsetWidth;
      const arrow = document.querySelector(".enter-action__block._right-block");
      arrow.style.left = `${btn1 + btn2 + 20}px`;
      arrow.classList.add("visible");
    }, 4000);
  },
};
</script>

<style lang="scss" scoped>
.enter {
  &-action {
    &.hidden {
      display: none !important;
    }
    &-wrapper {
      position: fixed;
      left: 0;
      bottom: 150px;
      width: 100%;
      z-index: 10;
      pointer-events: none;
    }
    &__icon {
      width: rem(43px);
      transform: rotate(30deg);
    }
    &__text {
      position: relative;
      top: 15px;
      left: 40px;
      font-size: rem(22px);
      line-height: 1;
      text-align: center;
      font-family: "Impact", sans-serif;
      transform: rotate(30.93deg);
    }
    @media (min-width: $sm) {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 410px;
      &__icon {
        width: rem(43px);
        transform: none;
        ._left-block & {
          width: rem(80px);
        }
        ._right-block & {
          transform: rotate(15deg);
        }
      }
      &__text {
        top: rem(15px);
        left: rem(45px);
        transform: rotate(40.93deg);
        ._left-block & {
          margin-left: rem(-60px);
          margin-bottom: rem(45px);
          transform: rotate(-20.93deg);
        }
      }
      &__block {
        transition: opacity 0.4s;
        &._right-block {
          position: absolute;
          left: 100%;
          bottom: -65px;
          transform: rotate(-15deg);
          opacity: 0;
          &.visible {
            opacity: 1;
          }
          ._top & {
            opacity: 0;
          }
        }
        &._left-block {
          opacity: 0;
          ._top & {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
