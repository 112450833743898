<template>
  <section class="prizes section">
    <div class="container">
      <div class="prizes__title">Выигрывай <br />призы</div>
      <Slick
        ref="slider"
        @afterChange="afterChange"
        :options="settings"
        class="prizes-list"
      >
        <div v-for="(item, i) in prizes" :key="i" class="prizes-list__item">
          <div class="prizes-list__item-img-wrapper">
            <img
              :src="require(`../assets/images/prizes/${item.img}`)"
              alt=""
              class="prizes-list__item-img"
              :class="`prizes-list__item-img--${i + 1}`"
            />
          </div>
          <div class="prizes-list__item-title" v-html="item.title"></div>
        </div>
      </Slick>
    </div>
  </section>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
import Slick from 'vue-slick';

export default {
  components: { Slick },
  data: () => ({
    publicPath: process.env.BASE_URL,
    settings: {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      fade: true,
      slidesToScroll: 1,
    },
    prizes: [
      {
        img: "prize_4.png",
        title: "Толстовка",
      },

      {
        img: "prize_2.png",
        title:
          'Сертификаты номиналом<br>500<span class="rub"></span>&nbsp;/&nbsp;1000<span class="rub"></span>/&nbsp;2000<span class="rub"></span>',
      },
      {
        img: "prize_1.png",
        title: 'Денежный приз 100&nbsp;000<span class="rub"></span>',
      },
      {
        img: "prize_3.png",
        title:
          'Сертификат Яндекс.Афиша <br>номиналом 2000<span class="rub"></span>',
      },
      {
        img: "prize_5.png",
        title: "Возврат до 20%",
      },
    ],
  }),
  methods: {
    afterChange(slideIndex) {
      this.gtmEvent(`prize_slide_${slideIndex + 1}`);
    },
  },
};
</script>

<style lang="scss">
.prizes {
  position: relative;
  margin-top: -1px;
  margin-bottom: -1px;
  height: 100vh;
  background-image: url("../assets/images/background.jpeg");
  .container {
    padding-top: rem(50px);
    padding-bottom: rem(85px);
  }
  &__title {
    font-family: "Impact", sans-serif;
    text-transform: uppercase;
    font-size: rem(32px);
    margin-bottom: rem(26px);
    line-height: 1.1;
  }
  &-list {
    position: relative;
    width: 100%;
    .slick-list {
      overflow: visible !important;
    }
    &.slick-slider {
      .slick-next {
        top: 50%;
        right: 0;
      }
      .slick-prev {
        top: 50%;
        left: 0;
      }
    }
    &__item {
      display: flex !important;
      flex-direction: column;
      &-img {
        display: block;
        max-width: 70%;
        max-height: 100%;
        &--1 {
          margin-right: rem(-15px);
        }
        &--2 {
          margin-right: rem(-15px);
        }
        &--3 {
          margin-right: rem(-20px);
        }
        &--4 {
          margin-right: rem(-20px);
        }
        &-wrapper {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: vh(275px);
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: vh(375px);
            height: vh(375px);
            background: url("../assets/images/prizes/shadow.svg") no-repeat
              center;
            background-size: contain;
            z-index: -1;
          }
        }
      }
      &-title {
        max-width: 80%;
        margin: auto auto 0;
        text-align: center;
        color: $yellow;
        font-family: "Impact", sans-serif;
        letter-spacing: 0.02em;
        font-size: rem(14px);
      }
    }
  }
  @media (min-width: $md) {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 55%;
      height: 60%;
      background: url("../assets/images/part_of_logo.png") no-repeat top right;
      transform: rotate(180deg) translateY(0%);
      background-size: 100%;
      z-index: 0;
    }
    .container {
      height: 100%;
      display: flex;
      padding-top: 0;
      padding-bottom: rem(0px);
    }
    &__title {
      position: absolute;
      top: vh(110px);
      left: 0;
      font-size: rem(52px);
    }
    &-list {
      width: 55%;
      margin: auto 0 auto auto;
      height: vh(375px);
      &__item {
        height: vh(375px);
        &-img {
          object-fit: contain;
          &--2 {
            width: 60%;
          }
          &--3 {
            width: 70%;
            margin-top: vh(30px);
            margin-right: vh(-30px);
          }
          &--4 {
            width: 70%;
            margin-top: vh(30px);
            margin-right: vh(-30px);
          }
          &--5 {
            width: 55%;
          }
          &-wrapper {
            height: 90%;
            &::before {
              width: vh(575px);
              height: vh(575px);
            }
          }
        }
        &-title {
          font-size: vh(22px);
          line-height: vh(26px);
        }
      }
    }
  }
}
</style>
