var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',{staticClass:"feedback-form"},[_c('div',{staticClass:"feedback-col"},[_c('inputText',{class:{
          error: _vm.validationStatus(_vm.$v.name) || this.errorMessage.name,
        },attrs:{"placeholder":"Ваше имя","field":_vm.email,"error":(!_vm.$v.name.required && _vm.$v.name.$error
            ? 'Обязательное поле'
            : !_vm.$v.name.rus && _vm.$v.name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
          error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
        },attrs:{"placeholder":"Ваш E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
            ? 'Обязательное поле'
            : !_vm.$v.email.email && _vm.$v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('Select',{class:{
          error: _vm.validationStatus(_vm.$v.subject) || this.errorMessage.subject,
        },attrs:{"color":"grey","placeholder":"Тема обращения","options":[
          { id: 1, label: 'Регистрация в акции' },
          { id: 2, label: 'Регистрация чека' },
          { id: 3, label: 'Призовой фонд' },
          { id: 4, label: 'Вопросы о продукте' },
          { id: 5, label: 'Технические вопросы' },
          { id: 6, label: 'Другое' },
        ],"field":"Регистрация в акции","error":(!_vm.$v.subject.required && _vm.$v.subject.$error
            ? 'Обязательное поле'
            : '') || this.errorMessage.subject},on:{"input":function($event){_vm.subject = $event.id}}}),_c('textArea',{class:{
          error: _vm.validationStatus(_vm.$v.message) || this.errorMessage.message,
        },attrs:{"placeholder":"Текст обращения","field":_vm.message,"error":(!_vm.$v.message.required && _vm.$v.message.$error
            ? 'Обязательное поле'
            : '') || this.errorMessage.message},on:{"input":function($event){_vm.message = $event}}})],1),_c('div',{staticClass:"feedback-col"},[_c('File',{attrs:{"chosenFileName":_vm.chosenFileName},on:{"submitMedia":_vm.submitMedia,"deleteFile":_vm.deleteFile}}),_c('div',{staticClass:"feedback-col feedback__checkbox_list visible-xs"},[_c('checkBox',{class:{
            error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
          },attrs:{"label":"ДАЮ СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ МНЕ ИСПОЛНИЛОСЬ 18 ЛЕТ","field":_vm.checkbox,"error":(!_vm.$v.checkbox.sameAs && _vm.$v.checkbox.$error
              ? 'Обязательное поле'
              : '') || _vm.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}})],1),_c('div',{staticClass:"feedback__text"},[_vm._v(" ПЕРСОНАЛЬНЫЕ ДАННЫЕ, ОТПРАВЛЕННЫЕ ЧЕРЕЗ ЭТУ ФОРМУ, БУДУТ ИСПОЛЬЗОВАНЫ ТОЛЬКО ДЛЯ ОТВЕТА НА КОНКРЕТНЫЙ ВОПРОС, А ТАКЖЕ В ЦЕЛЯХ АНАЛИТИКИ, И НЕ БУДУТ ИСПОЛЬЗОВАТЬСЯ В МАРКЕТИНГОВЫХ ЦЕЛЯХ В СООТВЕТСТВИИ С ПОЛОЖЕНИЕМ О КОНФИДЕНЦИАЛЬНОСТИ. ")]),_c('div',{staticClass:"feedback__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})])])],1),_c('div',{staticClass:"feedback-col feedback__checkbox_list hidden-xs"},[_c('checkBox',{class:{
          error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
        },attrs:{"label":"ДАЮ СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ МНЕ ИСПОЛНИЛОСЬ 18 ЛЕТ","field":_vm.checkbox,"error":(!_vm.$v.checkbox.sameAs && _vm.$v.checkbox.$error
            ? 'Обязательное поле'
            : '') || _vm.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }