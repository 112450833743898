<template>
  <div class="page">
    <div class="container _small-width">
      <h2 class="page__title">Где купить</h2>
      <div class="page__subtitle">во всех магазинах страны, а&nbsp;также</div>
      <div class="retails-content">
        <div class="retails-list _left-column">
          <div class="retails-list__title">
            съешь <br /><span class="_white">уже через полчаса:</span>
          </div>
          <a
            v-for="(item, i) in retailsLeft"
            :key="i"
            :href="item.link"
            target="_blank"
            class="retails-list__item"
            :style="'background-color: ' + item.background"
            @click="gtmEvent(item.gtm.label)"
          >
            <img :src="require(`../assets/images/logos/${item.logo}`)" alt="" />
            <div class="retails-list__item-front">
              <span>Перейти в магазин</span>
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                <path
                  d="M0 0L9.41176 8L0 16L0 12L4.63073 8L0 4L0 0Z"
                  fill="#3C140A"
                />
              </svg>
            </div>
          </a>
        </div>
        <div class="retails-list _right-column">
          <div class="retails-list__title">
            купи <br /><span class="_white"
              >про запас в интернет-магазине:</span
            >
          </div>
          <a
            v-for="(item, i) in retailsRight"
            :key="i"
            :href="item.link"
            target="_blank"
            class="retails-list__item"
            :style="'background-color: ' + item.background"
            @click="
              gtmEvent(item.gtm.category, item.gtm.action, item.gtm.label)
            "
          >
            <img :src="require(`../assets/images/logos/${item.logo}`)" alt="" />
            <div class="retails-list__item-front">
              <span>Перейти в магазин</span>
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                <path
                  d="M0 0L9.41176 8L0 16L0 12L4.63073 8L0 4L0 0Z"
                  fill="#3C140A"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div class="retails__note">
        * во всех магазинах, где представлена продукция брендов
      </div>
    </div>
    <div class="widget _fixed">
      <div class="widget-scanner"></div>
      <div class="widget-button"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    retailsLeft: [
      {
        logo: "samokat.svg",
        gtm: {
          label: "shop_samokat",
        },
        background: "#FF486F",
        link: "https://samokat.ru/?utm_source=Promo&utm_medium=Where_to_buy&utm_campaign=SNK_NY21",
      },
      {
        logo: "sber_market.png",
        gtm: {
          label: "shop_sber_market",
        },
        background: "#21A038",
        link: "https://sbermarket.ru/marketing/mars",
      },
    ],
    retailsRight: [
      {
        logo: "ozon.svg",
        gtm: {
          label: "shop_ozon",
        },
        background: "#0069FF",
        link: "https://www.ozon.ru/highlight/shokoladnaya-fabrika-mars-wrigley-1016019/",
      },
      // {
      //   logo: "wildberries.png",
      //   gtm: {
      //     category: "wildberries_chapter_CB",
      //     action: "wildberries_chapter_CB",
      //     label: "shops",
      //   },
      //   link: "https://www.wildberries.ru/brands/snickers?utm_source=Promo&utm_medium=Where_to_buy&utm_campaign=SNK_NY21",
      // },
      // {
      //   logo: "utkonos.png",
      //   gtm: {
      //     category: "utkonos_chapter_CB",
      //     action: "utkonos_chapter_CB",
      //     label: "shops",
      //   },
      //   link: "https://www.utkonos.ru/cat/52/479:452828?utm_source=Promo&utm_medium=Where_to_buy&utm_campaign=SNK_NY21",
      // },
      {
        logo: "yandex_market.png",
        gtm: {
          label: "shop_yandex_market",
        },
        link: "https://market.yandex.ru/special/mars_len",
      },
      {
        logo: "perekrestok.png",
        gtm: {
          label: "shop_perekrestok",
        },
        link: "https://www.vprok.ru/catalog/4353/shokoladnye-batonchiki?attr%5B2571%5D%5B%5D=snickers&sort=rate_desc",
      },
      {
        logo: "lenta.png",
        gtm: {
          label: "shop_lenta",
        },
        background: "#174D9E",
        link: "https://lavka.yandex.ru/search?text=snickers",
      },
    ],
  }),
  mounted() {
    this.$parent.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.retails {
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: rem(10px);
    margin-bottom: rem(22px);
    &::after {
      content: "";
      flex: 1 1 auto;
    }
    &__title {
      width: 100%;
      margin-bottom: rem(6px);
      font-size: rem(18px);
      line-height: 1.1;
      text-transform: uppercase;
      color: $yellow;
    }
    &__item {
      position: relative;
      display: flex;
      width: calc(50% - #{rem(5px)});
      height: rem(65px);
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow: 4.110000133514404px 4.110000133514404px 0px 0px
        rgba(0, 0, 0, 0.25);
      border-radius: rem(5px);
      overflow: hidden;
      @media (min-width: $sm) {
        height: rem(125px);
      }
      @media (min-width: $md) {
        width: rem(280px);
        height: rem(152px);
        box-shadow: 8.92605px 8.92605px 0px rgba(0, 0, 0, 0.25);
      }

      img {
        max-width: 70%;
        max-height: 80%;
      }
      &-front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $yellow;
        color: #3c140a;
        font-size: rem(18px);
        text-transform: uppercase;
        transition: 0.2s;
        opacity: 0;
        svg {
          display: block;
          margin-left: rem(7px);
          margin-bottom: rem(2px);
        }
      }
      &:hover {
        .retails-list__item-front {
          opacity: 1;
        }
      }
    }
  }
  &__note {
    font-family: "Impact", sans-serif;
    font-size: rem(14px);
    line-height: 1.1;
  }
  @media (min-width: $md) {
    &-content {
      display: flex;
    }
    &-list {
      gap: rem(20px);
      margin-bottom: 0;
      &__title {
        margin-bottom: rem(11px);
        font-size: rem(24px);
        line-height: rem(27px);
      }
      &._left-column {
        flex: 0;
        border-right: rem(4px) solid #522c21;
        padding-right: rem(43px);
      }
      &._right-column {
        padding-left: rem(43px);
      }
    }
    &__note {
      margin-top: rem(50px);
    }
  }
  &__note {
    margin-top: rem(24px);
  }
}
</style>
