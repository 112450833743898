<template>
  <div class="page winners">
    <div class="container _small-width">
      <h1 class="page__title">Победители</h1>
      <div class="winners__search">
        <form
          class="winners__search-form"
          v-on:submit.prevent="searchForm"
          :class="{
            error:
              validationStatus($v.searchQuery) || this.errorMessage.searchQuery,
          }"
        >
          <input
            class="winners__search-field"
            type="text"
            name="term"
            v-model="searchQuery"
            @focus="errorReset()"
            placeholder="Email "
          />
          <button type="submit" class="winners__search-button"></button>
          <div
            v-if="searchQuery"
            @click="formReset()"
            class="winners__search-clear"
          ></div>
          <div
            class="error-hint"
            v-if="
              validationStatus($v.searchQuery) || this.errorMessage.searchQuery
            "
            v-html="
              (!$v.searchQuery.required && $v.searchQuery.$error
                ? 'Обязательное поле'
                : !$v.searchQuery.email && $v.searchQuery.$error
                ? 'Некорректный e-mail'
                : '') || this.errorMessage.searchQuery
            "
          ></div>
        </form>
      </div>
      <Table
        class="winners-table"
        :thead="fields"
        v-if="winners && winners.length"
      >
        <template v-slot:tbody>
          <tr v-for="(item, index) in winners" :key="index">
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.email }}
            </td>
            <td>
              {{ item.prize }}
            </td>
            <td>
              {{ item.date }}
            </td>
          </tr>
        </template>
        <template v-slot:mobile>
          <table class="winners-tab-mobile">
            <tr v-for="(item, index) in winners" :key="index">
              <td>
                <table class="table-mobile__inner">
                  <tr>
                    <td>Имя</td>
                    <td>
                      {{ item.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>E-mail</td>
                    <td>
                      {{ item.email }}
                    </td>
                  </tr>
                  <tr>
                    <td>Приз</td>
                    <td>
                      {{ item.prize | typograf }}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата</td>
                    <td>
                      {{ item.date }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </template>
      </Table>
      <div class="no-results" v-if="!winners.length">
        {{
          noSearchResults ? "Ничего не найдено" : "Розыгрыш еще не проводился"
        }}
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../components/Table.vue";
import { required, email } from "vuelidate/lib/validators";
export default {
  components: { Table },
  validations: {
    searchQuery: { required, email },
  },
  data: () => ({
    fields: ["имя", "email", "приз", "Дата"],
    page: 1,
    perPage: 1,
    pages: [],
    searchQuery: null,
    date: null,
    total: 0,
    noResults: false,
    errorMessage: {
      date: null,
      searchQuery: null,
    },
    noSearchResults: false,
    winners: [],
    // winners: [
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   },
    //   {
    //     name: "Александр",
    //     email: "em•••••ail@mail.ru",
    //     prize: "Название какого-то приза",
    //     date: '15/07/2018',
    //   }
    // ]
  }),
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        searchQuery: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setPages(total) {
      this.pages = [];
      if (total) {
        for (let index = 1; index <= total.length; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate() {
      let page = this.page;
      this.$store.dispatch("GetWinners", { page: page }).then((response) => {
        this.winners = response.result;
      });
    },
    searchForm() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.page = 1;
      this.$store
        .dispatch("SearchWinners", { term: this.searchQuery })
        .then((response) => {
          this.winners = response.result;
          if (!this.winners) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    },
    formReset() {
      this.$v.$reset();
      this.searchQuery = null;
      this.$store
        .dispatch("GetWinners", { page: this.page })
        .then((response) => {
          this.winners = response.result;
        });
    },
  },
  created() {
    this.$store.dispatch("GetWinners").then((response) => {
      this.total = response.paging.total;
      this.winners = response.result;
    });
  },
  watch: {
    winners() {
      this.setPages(this.winners);
    },
  },
  mounted() {
    this.$parent.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.winners {
  &__search-field {
    width: 100%;
    padding: 2px rem(20px) 0 rem(17px);
    height: rem(50px);
    background: #fff;
    border-radius: rem(5px);
    font-size: rem(16px);
    color: $brown;
    outline: none;
    &::placeholder {
      color: #806f6a;
      text-transform: uppercase;
    }
  }
  &__search-form {
    position: relative;
    width: rem(305px);
    max-width: 100%;
    margin: rem(35px) 0 rem(28px);
  }
  &__search-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: rem(50px);
    height: rem(50px);
    background: $red url("../assets/images/icons/search.svg") no-repeat center;
    background-size: rem(23px);
    border-radius: 0 rem(5px) rem(5px) 0;
  }
  &__search-clear {
    position: absolute;
    top: 0;
    right: rem(64px);
    width: rem(18px);
    height: 100%;
    background: url("../assets/images/close_icon.svg") no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(30px);
    color: #fff;
    &-item {
      display: none;
      margin: 0 rem(8px);
      font-size: rem(19px);
      background: none;
      cursor: pointer;
      &:first-of-type,
      &:last-of-type {
        display: block !important;
      }
      &.current {
        font-family: "Bold";
        cursor: default;
      }
      &.visible {
        display: block;
      }
      &.first::after {
        content: "...";
        margin-left: rem(8px);
      }
      &.last::before {
        content: "...";
        margin-right: rem(8px);
      }
    }
  }
  .no-results {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: rem(220px);
    text-align: left;
    color: #fff;
  }
  @media (min-width: $md) {
    .no-results {
      width: 65%;
      font-size: rem(28px);
    }
  }
}
</style>
