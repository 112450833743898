import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Faq from "../views/Faq.vue";
import WhereToBuy from "../views/WhereToBuy.vue";
import Production from "../views/Production.vue";
import Winners from "../views/Winners.vue";
import ComingSoon from "../views/ComingSoon.vue";

import { isPortrait, isMobile, getOffset } from "../utils/device";

Vue.use(VueRouter);

const titlePrefix = "Зарядись SNICKERS! — ";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: titlePrefix + "Главная",
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    meta: {
      title: titlePrefix + "Вопросы и ответы",
    },
  },
  {
    path: "/where-to-buy",
    name: "WhereToBuy",
    component: WhereToBuy,
    meta: {
      title: titlePrefix + "Где купить",
    },
  },
  {
    path: "/production",
    name: "Production",
    component: Production,
    meta: {
      title: titlePrefix + "Продукты участвующие в акции",
    },
  },
  {
    path: "/winners",
    name: "Winners",
    component: Winners,
    meta: {
      title: titlePrefix + "Победители",
    },
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: titlePrefix + "Выигрывай призы",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
